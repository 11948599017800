import React, { Component } from 'react';
import Header from '../layout/Header';

export class Contact extends Component {
    render() {

        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h1 className="title">Contact Us</h1>
                                    </div>{/* /.page-title-captions */}
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                        <span>Contact</span>
                                    </div>
                                </div>
                            </div>{/* /.col-md-12 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>
                {/* page-title end*/}
                <section className="ttm-row zero-padding-section clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">{/* row */}
                            <div className="col-lg-5">
                                <div className="spacing-9">
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Come Visit Us At</h5>
                                            <h2 className="title">Our Address</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti-location-pin" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Office Address</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>Tower C, 2<sup>nd</sup> Floor, TEK Meadows,</p> <p>No.51, Rajiv Gandhi Salai, Sholinganallur, Chennai – 600119</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti ti-email" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Our Email</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p><a href="mailto:info@qeagle.com">info@qeagle.com</a></p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Send Message</h5>
                                            <h2 className="title">Drop Us A Line</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <form
                                        action='https://forms.zohopublic.com/TestLeaf/form/QeagleContactus/formperma/XGKFR9cXMzI1RndIyTMW8cmDG02m5Pc1D2G_aVkQB-A/htmlRecords/submit'
                                        name='form' id='form' class="row ttm-quote-form clearfix" method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>

                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <input name="SingleLine" type="text" class="form-control bg-white" placeholder="Full Name*" required />
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <input name="SingleLine1" type="text" class="form-control bg-white" placeholder="Phone Number*" required />
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <input name="SingleLine2" type="text" class="form-control bg-white" placeholder="Email Address*" required />

                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <div class="form-group">
                                                <input name="SingleLine3" type="text" class="form-control bg-white" placeholder="Subject" />
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="MultiLine" rows="5" maxlength="65535" placeholder="Write A Message..." required="" class="form-control bg-white"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="text-left"><button type="submit" id="submit"
                                                class="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                                                value="true">Contact Us</button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                <section className="ttm-row map-section res-991-p-0 clearfix">
                    <div className="map-wrapper">
                        <iframe height="100%" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Qeagle Assurance&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
                    </div>
                </section>
            </div>
        )
    }
}

export default Contact;

