import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      const { photoIndex, isOpen } = this.state;
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        
        return (       
          <div className="site-main">   
            <Header/>            
            {/* home banner */}
            <CarouselPage/>{/* home banner end*/}            
            {/* service-section */}     
            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
                  <div className="col-md-4">
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-system" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Quality Services</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>Highest Customer Satisaction</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                          <i className="flaticon flaticon-clock" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>24/7 Support</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>support@qeagle.com</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-developer" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Expert &amp; Professional</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>Certified automation engineers</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </section>
            {/* service-section end */}
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>About us</h5>
                        <h2 className="title">20 years of process and test <span>automation experience</span></h2>
                      </div>
                      <div className="title-desc">
                        <p>Qeagle services helps customers to leverage our test automation and RPA expertise and experience to deliver personalized and engaging QA, RPA automation experiences and tailored solutions.</p>
                        <p>We have highly experienced and certified team for test automation (front and back-end), performance engineering and RPA development.</p>
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                    <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">UI Automation </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">API Automation</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Micro Services Automation</span></li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Robotic Process Automation</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Bot Development</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Orchestrator Design</span></li>
                        </ul>
                      </div>
                    </div>{/* row END*/}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="fs-16 ttm-textcolor-darkgrey">
                      <span>Call to ask <a className="fw-500 ttm-textcolor-darkgrey"><u>any question</u></a></span>
                      <strong className="fs-18"><a href="mailto:contact@qeagle.com">: contact@qeagle.com</a></strong> 
                    </div>
                  </div>
                  <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper">
                        <img className="img-fluid" src="images/single-img-one.png" title="single-img-one" alt="single-img-one" />
                      </div>{/* ttm_single_image-wrapper end */}
                      {/*featured-icon-box*/}
                      <div className="about-overlay-01">
                        <p className="mb-0">Qeagle Assurance.</p>
                      </div>
                      {/* featured-icon-box end*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* history-section */}
            <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h2 className="title">Our automation engineering includes: <span>Products &amp; Services</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Consulting</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                          Expertise in automation consulting for small, mid and large software companies, for their automation COE.
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-computer" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Resourcing</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Qeagle will bridge the gap in the employment market and find the best talent available for you.                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" ></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Projects</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                          We deliver projects of different scale and different sizes but only in automation engineering.
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" ></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-global-1" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Products</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                          Our products - Tuna and Ruto are unique in the market offering for enterprises and public.
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" ></a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Pre Sales</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                          Qeagle support organizations like you to win opportunities through our automation skills.
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" ></a>
                      </div>
                    </div>
                  </div>
                </Slider>
                
                <div className="row">
                  <div className="col-md-12 text-center mt-5 res-991-mt-30">
                    <p className="mb-0">Don’t hesitate, contact us for better help and services. <strong><u><a  className="ttm-textcolor-darkgrey"> View more Services</a></u></strong></p>
                  </div>
                </div>
              </div>
            </section>
            {/* history-section end*/}
            {/* zero-padding-section */}
            <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 clearfix">
              <div className="container-xl">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    {/* col-bg-img-one */}
                    <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                    </div>
                    {/* Responsive View image */}
                    <img src="images/bg-image/col-bgimage-1.jpg" className="ttm-equal-height-image img-fluid" alt="col-bgimage-1" />
                  </div>
                  <div className="col-lg-6 z-9">
                    <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                      <div className="spacing-1">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                          <div className="title-header">
                            <h5>Why Qeagle?</h5>
                            <h2 className="title">We are preferred due to our <span> pursuit of quality and unwavering client relationships standards</span></h2>
                          </div>
                          <div className="title-desc">
                            <p>Our products and services converge business and technology experts to help to manage automation services at ease.</p>
                          </div>
                        </div>{/* section title end */}
                        <div className="row mt-40">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                  <i className="flaticon flaticon-process" />
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>Products</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>At Qeagle, we built Tuna (Codeless automation tool) and Ruto(Opensource selectors tool).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                  <i className="flaticon flaticon-system" />
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>RPA Workflows and Bots</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>Successfully developed 600+ business processes, 2K+ Workflows and 100+ live and on demand bots</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-4 mb-4" />
                        </div>
                        {/* separator */}
                        <div className="row">
                          <div className="col-12">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style2 left-icon icon-align-top">
                              <div className="featured-icon">{/* featured-icon */}
                                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                                  <i className="flaticon flaticon-data" />
                                </div>
                              </div>
                              <div className="featured-content">
                                <div className="featured-title">{/* featured title */}
                                  <h5>Test Automation Engineering</h5>
                                </div>
                                <div className="featured-desc">{/* featured desc */}
                                  <p>Built and executed 1M+ tests on Selenium WebDriver, Cypress, Protractor, Rest Assured and PostMan.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* zero-padding-section end */}
            {/* first-row-title-section */}
            <section className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img4 clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">{/* row */}
                  <div className="col-lg-8 offset-lg-2">
                    <div className="text-center">
                      {/* section-title */}
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">We Provide Outsourced Automation Services <span className="ttm-textcolor-white">For Small &amp; Mid-Sized Business</span></h2>
                        </div>
                        <div className="title-desc">Appropriate for your specific business, making it easy for<br /> you to have quality IT services.</div>
                      </div>{/* section-title end */}
                      <div className="mt-5">
                        <a  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">More Services</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* first-row-title-section end*/}
            {/* testimonial-section */}
            <section className="ttm-row zero-padding-section clearfix">
              <div className="container-xl">
                <div className="row no-gutters mt_100 res-991-mt-0">
                  <div className="col-md-6">
                    <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                      <div className="layer-content">{/* layer-content */}
                        <div className="spacing-2">{/* spacing-2 */}
                          {/* section title */}
                          <div className="section-title ttm with-desc clearfix">
                            <div className="title-header">
                              <h5>What We Do</h5>
                              <h2 className="title">We help your app quality <span className="ttm-textcolor-white">Exponentially</span></h2>
                            </div>
                          </div>{/* section title end */}
                          {/* progress-bar */}
                          <div className="ttm-progress-bar">
                            <div className="progress-bar-title float-left">Test Automation</div>{/* progress-bar-title */}
                            <label className="float-right">90%</label>
                            <MDBProgress className="w-100" material value={90}></MDBProgress>
                          </div>
                          {/* progress-bar END */}
                          {/* progress-bar */}
                          <div className="ttm-progress-bar">
                            <div className="progress-bar-title float-left">RPA Development</div>{/* progress-bar-title */}
                            <label className="float-right">85%</label>
                            <MDBProgress className="w-100" material value={85}></MDBProgress>
                          </div>
                          {/* progress-bar END */}
                        </div>{/* spacing-2 END */}
                      </div>{/* layer-content END */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                      <div className="layer-content">
                        <div className="testimonial-slide style1">
                          <Slider className="row slick_slider" arrows={false} dots={true}>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">{/* testimonials-content */}
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">{/* testimonials-img */}
                                      <img className="img-center" alt="testimonial-img" src="images/testimonial/01.jpg" />
                                    </div>
                                  </div>
                                  <blockquote>Qeagle automation team was brilliant in designing the entire test suite to run within 20 minutes from 8.5 hours. Amazing !!</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">{/* testimonials-caption */}
                                    <h6>James Broomhead</h6>
                                    <label>Product Manager, TESM</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">{/* testimonials-content */}
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">{/* testimonials-img */}
                                      <img className="img-center" src="images/testimonial/02.jpg" alt="testimonial-img" />
                                    </div>
                                  </div>
                                  <blockquote>Qeagle's RPA team developed and deployed 25 bots and that performed 100K+ transactions every month. Great Effort!</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">{/* testimonials-caption */}
                                    <h6>Dylan Souza</h6>
                                    <label>Business Head, SERVCO</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="testimonials text-center"> 
                                <div className="testimonial-content">{/* testimonials-content */}
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">{/* testimonials-img */}
                                      <img className="img-center" alt="testimonial-img" src="images/testimonial/03.jpg" />
                                    </div>
                                  </div>
                                  <blockquote>Qeagle's performance engineering efforts to validate our new ERP product for 50K users were phenomenal. Kudos to their team !</blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />&nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">{/* testimonials-caption */}
                                    <h6>Sanjay Mishra</h6>
                                    <label>IT Head, L&T ECC</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* testimonial-section end */}
            {/* process-section */}
            <section className="ttm-row process-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    {/* section title */}
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h5>how it works?</h5>
                        <h2 className="title">Check Out Our Work <span>Process</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                {/* row */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ttm-processbox-wrapper">
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-one" title="step-one" src="images/step-one.jpg" />
                          <div className="process-num">
                            <span className="number">01</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title"><h5>Contact Us</h5></div>
                          <div className="ttm-box-description">You can write to sales@qeagle.com with your requirment information</div>
                        </div>
                      </div>
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-two" title="step-two" src="images/step-two.jpg" />
                          <div className="process-num">
                            <span className="number">02</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title"><h5>Choose Trial Service</h5></div>
                          <div className="ttm-box-description">We can sign NDA with you and you can try a month of ANY FREE automation services</div>
                        </div>
                      </div>
                      <div className="ttm-processbox text-center">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-three" title="step-three" src="images/step-three.jpg" />
                          <div className="process-num">
                            <span className="number">03</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title"><h5>Receive Custom Plan</h5></div>
                          <div className="ttm-box-description">We will provide a custom plan for your automation requirments that fits your bill</div>
                        </div>
                      </div>
                      <div className="ttm-processbox text-center res-991-mb-0">
                        <div className="ttm-box-image">
                          <img className="img-fluid" alt="step-four" title="step-four" src="images/step-four.jpg" />
                          <div className="process-num">
                            <span className="number">04</span>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title"><h5>Let’s Make it Happen</h5></div>
                          <div className="ttm-box-description">We will provide the best valued services to make you and your customer successful</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* row end */}
              </div>
            </section>
            {/* portfolio-section */}
            <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl pb-5 res-991-pb-0">
                <div className="row align-items-center ttm-textcolor-white">
                  <div className="col-lg-5 res-991-text-center">
                    <div className="section-title style3 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h5>We Build Relationships</h5>
                        <h2 className="title">Explore recent <span className="ttm-textcolor-white">Projects</span></h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 res-991-text-center">
                    <div className="title-desc">Our list of recent automation projects including test automation, RPA development and performance engineering.</div>
                  </div>
                  <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                    <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"> Read More </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="ttm-row mt-10 zero-padding-section clearfix">
              <div className="container-fluid p-0">
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
                <Slider className="row slick_slider ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={5}>
                  <div className="ttm-box-col-wrapper">
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/01.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>Oracle ERP - Test Automation</a></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/02.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>ServiceNow - Web & API Automation</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/03.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>Salesforce - Web Automation</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/04.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>Retail App - Test Automation</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/05.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>RPA UiPath - Bot Development</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                  <div className="ttm-box-col-wrapper">
                    {/* featured-imagebox-portfolio */}
                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" alt="" src="images/portfolio/06.jpg" />
                      </div>{/* featured-thumbnail end*/}
                      {/* ttm-box-view-overlay */}
                      <div className="ttm-box-view-overlay">
                        <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                        </div>
                      </div>{/* ttm-box-view-overlay end*/}
                      <div className="featured-content">
                        <div className="featured-title">{/* featured-title */}
                          <h5><a>Product - Performance Engineering</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-thumbnail */}
                  </div>
                </Slider>
              </div>
            </section>
            {/* process-section end */}
          </div>
        )
    }
}


export default Home;
