import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
state = {
  collapse1: false,
  collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}

toggleSingleCollapse = collapseId => {
  this.setState({
    ...this.state,
    [collapseId]: !this.state[collapseId]
  });
}

render() {
  return (
    <Router>
        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Home</span>
                        </MDBDropdownToggle>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Services</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/test_automation'}>Test Automation</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/process_automation'}>Process Automation (RPA)</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/devops_automation'}>DevOps Automation</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/cloud_automation'}>Cloud Automation</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/performance_engineering'}>Peformance Engineering</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Products</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Tuna'}>TUNA</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Ruto'}>RUTO</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Our Team</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Team'}>Leadership</MDBDropdownItem>                        
                        </MDBDropdownMenu>
                   </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Contact Us</span>
                        </MDBDropdownToggle>       
                        <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact'}>Contact Us</MDBDropdownItem>                        
                        </MDBDropdownMenu>       
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>
    </Router>
    );
  }
}

export default Mobilemenu;