import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box1';

export class Ruto extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">RUTO - OpenSource Locators Tool</h1>
                            </div>{/* /.page-title-captions */}
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                <section className="ttm-row project-single-section clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 col-xl-8">
                            <h4>Automated Locator Selection</h4>
                            <p>For most of the automation engineers, writting complex and stable xpath or CSS is high time consuming or painful activity. At Qeagle, we want to simplify this solution across browsers.</p>
                            <p>Ruto is available for chrome, firefox and edge as extenion. With just right clicks on the element, the element's locator can be found through click on the ‘add to Ruto’ option, which will append the element to Ruto and can be viewed by clicking on the extension where you can select from the list of options available.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper mt-35">
                                        <img className="img-fluid" src="images/single-img-twentytwo.jpg" title="single-img-twenty" alt="single-img-fourteen" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                                <div className="col-md-6">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper mt-35">
                                        <img className="img-fluid" src="images/single-img-twentythree.jpg" title="single-img-twenty" alt="single-img-twenty" />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>
                                <div className="col-12 mt-35">
                                    <p>One of the specialties about Ruto is that it can even generate the entire code snippet and copy/paste it to the code editor. Altogether, it will not take more than a minute to produce a XPath for test automation, get Ruto to experience speed automation.</p>
                                </div>
                                {/* separator */}
                                <div className="col-12">
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-35" />
                                    </div>
                                </div>
                                {/* separator */}
                                <div className="col-12">
                                    <div className="ttm-nextprev-bottom-nav mb-4">
                                        <nav className="navigation post-navigation">
                                        <div className="nav-links">
                                            <div className="nav-links">
                                            <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-left"><i className="fa fa-angle-double-left" />Previous</a> 
                                            <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-right float-md-right">Next<i className="fa fa-angle-double-right" /></a> 
                                            </div>
                                        </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                <Detailbox />
                            </div>
                        </div>
                        {/* row end*/}
                        {/* row */}
                        <div className="row ttm-pf-single-related-wrapper ">
                            <div className="col-12">
                                <h4 className="mb-30">Related Projects</h4>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/14.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>Caratlane, India</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>Bridge and OneCRM Applications</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/15.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>L&T ECC, India</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>ERP Management for Constructions</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/13.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>ITOrizon, USA</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>Oracle ERP - Warehouse Management</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                        </div>{/* row end*/}
                    </div>
                </section>
            </div>
        )
    }
}


export default Ruto;