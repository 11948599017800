import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class DevOps_Automation extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">DevOps Automation</h1>
                            </div>{/* /.page-title-captions */}                           
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                <div className="ttm-service-description">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src="images/single-img-eighteen.jpg" alt="single-img-eighteen" />
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <h3 className="res-767-mt-20">Build Automation Tests</h3>
                                        <p>Qeagle's DevOps test automation involves practices such as 'shift left' and continuous  automated testing. We begin with automating the unit tests  and followed by API layers. This increases velocity because less testing is needed at the UI level.</p>
                                        <p className="mb-0">Working with QA DevOps Consulting Partners like Qeagle can help customers to implement the best DevOps automation practices and methodologies that leads to benefits like continuous & scaled automated tests with monitoring.</p>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-6">
                                        <h4>CI/CD Automated Tests</h4>
                                        <p>Building on CICD’ automation strengths, Qeagle assist customers to increase the quality of their development and enables continuous delivery at scale, both on-premises and on cloud.</p>
                                        <p>We offer our technical expertise, consulting and integration services as well as application test tools & frameworks to help businesses drive continuous integration and delivery on any engagement for faster deployment of applications.</p>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="images/single-img-nineteen.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                    </div>
                                    <h3>Containerization of Automated Builds</h3>
                                    <div className="ttm_single_image-wrapper mb-35 res-991-mb-20">
                                        <img className="img-fluid" src="images/single-img-fourteen.jpg" alt="single-img-fourteen" />
                                    </div>
                                    <p>With strategic collaborations and partnerships, Qeagle delivers on the promise of Scalability, Elasticity and Flexibility to our customers on cloud. Qeagle complements our partner offerings to enhance test automation and developer experience. </p>
                                    <p>This in turn helps our customers focus on their strategic business goals.</p>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-6">
                                        <h4>Dockers and Kubernetes</h4>
                                        <p>Qeagle has helped some of the mid & small companies with their container adoption strategies and services to bridge internal skills gaps and expertise. We are proud to be one of the few companies globally to have achieved certifications in both Docker and Kubernetes automation.</p>
                                        <p>Our experts can help to determine the proper tools, develop the architectures, ensure optimal deployment to avoid painful mistakes.</p>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="images/single-img-nineteen.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar/>
                        </div>
                    </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default DevOps_Automation;