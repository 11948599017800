import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class Cloud_Automation extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Cloud Automation</h1>
                            </div>{/* /.page-title-captions */}
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                {/* section title */}
                                <div className="section-title with-desc text-left clearfix">
                                <div className="title-header">
                                    <h5>We Automate everything</h5>
                                    <h2 className="title">Cloud Automation Services</h2>
                                </div>
                                </div>{/* section title end */}
                                <div className="ttm_single_image-wrapper mb-35">
                                <img className="img-fluid" src="images/single-img-twelve.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                <h4>AWS Automation</h4>
                                <p>Qeagle is a award-winning provider of DevOps and cloud professional services, products, and solutions, specializing in modern infrastructures on AWS. We work as an extension of your team and happy to tackle your toughest test automation challenges in cloud. All so your team can deliver innovation faster and create awesome customer experiences.</p>
                                <p>Qeagle assisted several emerging organizations in AWS across the high tech, manufacturing, healthcare, and logistics industries globally. We have also made significant investments in building cloud adoption frameworks related to test automation and expertise in cloud strategy and deployment.</p>
                                {/* separator */}
                                <div className="separator">
                                    <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                </div>
                                {/* separator */}
                                <div className="row">
                                    <div className="col-md-8">
                                    <h4>Microsoft Azure Automation</h4>
                                    <p className="mb-35 res-991-mb-20">With expertise in DevOps consulting and Azure test automation services, our cloud automation architects have dedicated to assist many organizations in implementing deployment automation and Infrastructure as Code. With the objective of reducing human errors and manual control, our automation services help businesses overcome challenges in software development and help increase employee productivity through Azure Container Instances/Services and Azure Kubernetes Services.</p>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="ttm_single_image-wrapper res-767-mt-20">
                                        <img className="img-fluid" src="images/single-img-sixteen.jpg" alt="single-img-sixteen" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar/>
                        </div>
                    </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Cloud_Automation;