import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                    <li className="menu-hasdropdown"><a>Services</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/test_automation'}>Test Automation</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/process_automation'}>Process Automation (RPA)</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/devops_automation'}>DevOps Automation</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/cloud_automation'}>Cloud Automation</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/performance_engineering'}>Peformance Engineering</a></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Products</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/Tuna'}>TUNA</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/Ruto'}>RUTO</a></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Our Team</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/Team'}>Leadership</a></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/Contact'}>Contact Us</a></li>
                </ul>
            </nav>
    
            )
    }
}