import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class Performance_engineering extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Performance Engineering</h1>
                            </div>{/* /.page-title-captions */}
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                {/* section title */}
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h5>Performance Testing and Engineering</h5>
                                        <h2 className="title">Responsiveness, Reliability and Robustness</h2>
                                    </div>
                                </div>{/* section title end */}
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/single-img-twelve.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                    <h3>Capacity Planning</h3>
                                    <p>Planning and budgeting for your next infrastructure, application upgrade and enhancement or data migrations?</p>
                                    <p>Get a complete visibility of your app, infra demand and resource usage. Plan, Monitor and identify whether the resources are being utilised effectively and efficiently before making any further investment. Baseline your application performance for future comparison.</p>
                                    <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src="images/single-img-fourteen.jpg" alt="single-img-fourteen" />
                                    </div>
                                    <h3>Performance Testing</h3>
                                    <p>We help our customers to monitor and analyse from different perspectives of the application such as end user experience, component level, system level and code level to identify areas where performance degradation occurs and what type of enhancements are required.</p>
                                    <p>Qeagle’s Performance Testing and Engineering solution combines tool expertise with Diagnosis solutions. Our Performance Testing guarantees the need-for-speed necessities of enterprise digital transformations by designing custom solutions that enhance the business value chain.</p>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h3>Performance Testing Framework</h3>
                                        <p>Our solution is based on the custom design approach, that helps to calibrate think time, pacing for a given user load or achieve the required throughput. You can monitor, collect and analyze various system performance metrics across technology stack like resource utilization, web / app / database performance metrics, mobile device metrics and network metrics using market tools like Dynatrace, NewRelic, CA, Applicare and more.</p>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="images/single-img-fifteen.jpg" alt="single-img-fifteen" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar/>
                        </div>
                    </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Performance_engineering;