import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class Test_Automation extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Test Automation</h1>
                            </div>{/* /.page-title-captions */}
                          </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                {/* ttm-service-single-content-are */}
                                {/* ttm-service-single-content-are */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/single-img-twelve.jpg" alt="single-img-twelve" />
                                    </div>
                                    <div className="ttm-service-description">
                                    <h3>Functional Test Automation</h3>
                                    <p>Qeagle provides clients across domains with Test Automation services, a key lever in driving efficiency, effectiveness and cost reduction in QA organizations.</p>
                                    <p>Qeagle being the leader in Test Automation space, we have developed commercial and open source tools and competencies to provide improved automation quality, early defect detection and cycle time reduction.</p>
                                    <div className="row no-gutters box-shadow mb-35">
                                        <div className="col-md-4 col-sm-4">
                                        {/*ttm-fid*/}
                                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                            <div className="ttm-fid-left">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-global-1 ttm-textcolor-skincolor" />
                                            </div>
                                            </div>
                                            <div className="ttm-fid-contents text-left">
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={12} duration={5} /> +
                                            </h4>
                                            <h3 className="ttm-fid-title">Years Experience</h3>
                                            </div>
                                        </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                        {/*ttm-fid*/}
                                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                            <div className="ttm-fid-left">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-padlock ttm-textcolor-skincolor" />
                                            </div>
                                            </div>
                                            <div className="ttm-fid-contents text-left">
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={201} duration={5} /> +
                                            </h4>
                                            <h3 className="ttm-fid-title">Happy Clients</h3>
                                            </div>
                                        </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                        {/*ttm-fid*/}
                                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                            <div className="ttm-fid-left">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-developer ttm-textcolor-skincolor" />
                                            </div>
                                            </div>
                                            <div className="ttm-fid-contents text-left">
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={34} duration={5} /> +
                                            </h4>
                                            <h3 className="ttm-fid-title">New Customers</h3>
                                            </div>
                                        </div>{/* ttm-fid end*/}
                                        </div>
                                    </div>
                                    <p className="mb-35">End-to-end automation engineering consulting and services for strategy, tools, frameworks, proof of concepts, Regression automation, UI Automation, Micro Services / API Automation, Mobile Automation, BDD automation. Our experience spread across various opensource tools like Selenium Webdriver, Cypress, JMeter, Protractor, Puppeteer, Rest Assured, PostMan and many more.</p>
                                    <h3>Why Choose Us?</h3>
                                    <div className="row mt-35">
                                        <div className="col-lg-12">
                                            <div className="row m-0 box-shadow spacing-11">
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <div className="ttm_single_image-wrapper">
                                                        <img className="img-fluid" src="images/single-img-thirteen.jpg" alt="single-img-thirteen" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <p className="mb-30 res-1199-mt-20">We are one stop end to end automation specialists, and we setup of a robust framework is all that it takes for a sturdy testing process on the long run.</p>
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">50000+ automated tests delivered </span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">2M+ tests executed on different browsers</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Front (UI) and Back (Services) automation</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Selenium, Cypress, Protractor, Puppeteer</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Scalable (Grid) and Containerized tests</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mt-35">End to End Automation Services</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                                        {/* acadion */}
                                        <Accordion className="accordion style2">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        When do we need end to end automation?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p className="mb-0">
                                                        If your application is built on modern technologies, then end to end automation covering both front and back-end automation would be the right choice. Remembering Mark's testing pyramid for your app automation would be the preference.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        What tools and frameworks are used in the UI automation?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p className="mb-0">
                                                        Qeagle uses wide opensource tools by technology stacks and customer preference like Selenium WebDriver, Cypress, Protractor and Puppeteer. We use different framework design patterns like Page/Pattern Object Models, BDD/TDD models through Cucumber/SpecFlow and Hybrid patterns.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        What tools and frameworks are used in Services automation?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p className="mb-0">
                                                        Qeagle's backend automation team have developed several frameworks integrated model using Spring Boot - Matschie and Makaia as well Contract testing frameworks. Qeagle team has greatest expertise on tools/frameworks like WireMock, RestAssured, PostMan, Karate etc.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-3 widget-area">
                                <Sidebar/>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Test_Automation;