import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class Process_Automation extends Component {
    render() {
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">Robotic Process Automation</h1>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                        {/* ttm-service-single-content-are */}
                        {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                {/* section title */}
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h5>We Build Workflows and Bots</h5>
                                        <h2 className="title">Process Automation</h2>
                                    </div>
                                </div>{/* section title end */}
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/single-img-twelve.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                    <h3>UiPath Automation</h3>
                                    <p>Qeagle has proven its capabilities with more than 600+ process automations and 1100+ workflows, 400K+ transactions and 50+ Live and on demand bots.</p>
                                    <p>With over 20+ certified UiPath developers, architects and a dedicated Centre of Excellence and delivery team, Qeagle has positioned itself as the preferred process automation partner. We built customized Robotic Enterprism frameworks and custom activities to make your process automation easier than what you think.</p>
                                    <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src="images/single-img-fourteen.jpg" alt="single-img-fourteen" />
                                    </div>
                                    <h3>BluePrism Automation</h3>
                                    <p>Qeagle delivers Blue Prism digital workforce designed to automate complex and end-to-end operational activities.We can help you conceive Blue Prism automation strategy, plan the roadmap for automation roll out, design Blue Prism RPA Centre of Excellence, present automation PoCs and more.</p>
                                    <p>Qeagle offers a flexible suite of managed services involving Blue Prism RPA support, monitoring, maintenance and that are individually tailored to the needs of your requirements. We also ensure the deployments seamlessly enable integrations with your existing systems and processes.</p>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-md-6">
                                        <h3>Automation Anywhere</h3>
                                        <p>Automation Anywhere (AA) offers the world's only web-based and cloud native intelligent automation platform combining RPA, artificial intelligence, machine learning and analytics right out of the box. </p>
                                        <p>Qeagle created several bots using Automation Anywhere for the Supply Chain business functions. It matches vendor invoices with purchase orders using machine learning.</p>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="images/single-img-fifteen.jpg" alt="single-img-fifteen" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar/>
                        </div>
                    </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
    }
}


export default Process_Automation;