import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';

export class Tuna extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                    <h1 className="title">TUNA - Codeless Test Automation Tool</h1>
                                    </div>{/* /.page-title-captions */}
                                </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                <section className="ttm-row project-single-section clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-6 col-xl-8">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mb-35">
                                <img className="img-fluid" src="images/single-img-twenty.jpg" title="single-img-twenty" alt="single-img-twenty" />
                            </div>{/* ttm_single_image-wrapper end */}
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <Detailbox />
                            </div>
                        </div>
                        {/* row end*/}
                        {/* row */}
                        <div className="row">
                            <div className="col-12">
                                <h4>About Tuna</h4>
                                <p>Tuna, the test automation platform for web, services and mobile applications. You can automate your tests incredibly fast and with code-less experience.Tuna is an unique test automation platform for everyone – from managers to business analysts to developers to manual testers to support engineers.</p>
                                <h4>Features</h4>
                                <p>With Tuna, you can create automated tests incredibly fast at about 2x effort of the manual testing efforts. You can write (codeless) once by any tech and non tech skilled team & run anywhere - cloud or on premises.</p>
                                <p className="mb-35 res-991-mb-20">It is a test platform and not just tool and that means you can manage your test data, test scripts, test suite and test results. Incase if your application under test changed drastically, Tuna can make your test automation less fragile with simpler modification.</p>
                            </div>
                            <div className="col-md-4">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style3 left-icon icon-align-top">
                                    <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-algorithm" />
                                    </div>
                                    </div>
                                    <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Expert Team</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our product team is available 24x7 to ensure you are supported fully.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style3 left-icon icon-align-top">
                                    <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-padlock" />
                                    </div>
                                    </div>
                                    <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Free Consultation</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>You can call us to book your FREE consultation and trial with us.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box style3 left-icon icon-align-top">
                                    <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-process-1" />
                                    </div>
                                    </div>
                                    <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Lowest Cost</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>On comparative scale,Tuna is priced low with high quality.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <h4 className="mt-35">Tuna Result &amp; Benefits</h4>
                                <p className="mb-0">Our customers across globe has benefited through time reduction of regression suite by 300% lesser and cost by 500%. Tuna runs on demand schedule or based on new builds with integration with your CI/CD ecosystem, thus fully in alignment with your devops.</p>
                            </div>
                            <div className="col-12">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper mt-35">
                                    <img className="img-fluid" src="images/single-img-twentyone.jpg" title="single-img-twentyone" alt="single-img-twentyone" />
                                </div>{/* ttm_single_image-wrapper end */}
                            </div>
                            {/* separator */}
                            <div className="col-12">
                                <div className="separator">
                                    <div className="sep-line mt-35 mb-35" />
                                </div>
                            </div>
                            {/* separator */}
                            <div className="col-12">
                                <div className="ttm-nextprev-bottom-nav mb-4">
                                    <nav className="navigation post-navigation">
                                    <div className="nav-links">
                                        <div className="nav-links">
                                        <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-left"><i className="fa fa-angle-double-left" />Previous</a> 
                                        <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-right float-right">Next<i className="fa fa-angle-double-right" /></a> 
                                        </div>
                                    </div>
                                    </nav>
                                </div>
                            </div>
                        </div>{/* row end*/}
                        {/* row */}
                        <div className="row ttm-pf-single-related-wrapper ">
                            <div className="col-12">
                                <h4 className="mb-30">Related Projects</h4>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/04.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>Servco , Hawaii</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>Oracle Procurement Automation</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/05.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>Winzer, USA</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>Pepperi - Sales Automation</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/portfolio/06.jpg" alt="image" />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>TESM, UK</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a>Salesforce - WEB and API Automation</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>
                        </div>{/* row end*/}
                    </div>
                </section>
            </div>
        )
    }
}


export default Tuna;