import React, { Component } from 'react';

export class Detailbox extends Component {
    render() {
        return (
            <div className="ttm-pf-single-detail-box mb-35">
                <div className="ttm-pf-single-title">
                <h5>TUNA</h5>
                </div>
                <p>Tuna is an unique test automation platform for everyone – from managers to business analysts to developers to manual testers to support engineers.</p>
                <ul className="ttm-pf-detailbox-list">
                <li><i className="fa fa-user" /><span> Client : </span>Servco</li>
                <li><i className="far fa-bookmark" /><span> Category :</span>Oracle ERP</li>
                <li><i className="fa fa-calendar" /><span> Date :</span>16 Sept 2020</li>
                <li><i className="ti ti-world" /><span> Website : </span>servco.com</li>
                <li><i className="fa fa-map-marker" /><span>Location : </span>Hawaii</li>
                </ul>
            </div>
            )
    }
}


export default Detailbox;