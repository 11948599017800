import React, { Component } from 'react';

export class Detailbox extends Component {
    render() {
        return (
            <div className="ttm-pf-single-detail-box mb-35">
                <div className="ttm-pf-single-title">
                <h5>Ruto</h5>
                </div>
                <p>The browser extension that assist you to generate XPath and CSS Patterns for your application under test with few clicks. It can as well generate Selenium WebDriver or Cypress code with few clicks.</p>
                <ul className="ttm-pf-detailbox-list">
                <li><i className="fa fa-user" /><span> Category : </span>OpenSource</li>
                <li><i className="far fa-bookmark" /><span> Type :</span>Browser Extension</li>
                <li><i className="fa fa-calendar" /><span> Date :</span>01 July 2020</li>
                <li><i className="ti ti-world" /><span> Website : </span>testleaf.com</li>
                <li><i className="fa fa-map-marker" /><span>Location : </span>Chennai</li>
                </ul>
            </div>
            )
    }
}


export default Detailbox;