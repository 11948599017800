import React, { Component } from 'react';


export class Sidebar extends Component {
    render() {     
        return (            
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><a activeClassName="active" href={process.env.PUBLIC_URL + '/test_automation'}>Test Automation</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/process_automation'}>Process Automation</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/devops_automation'}>DevOps Automation</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/cloud_automation'}>Cloud Automation</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/performance_engineering'}>Performance Testing</a></li>
                    </ul>
                </div>
                <div className="widget widget-text">
                    <h3 className="widget-title">About Us</h3>
                    <div className="ttm-author-widget">
                    <div className="author-widget_img">
                        <img className="author-img img-fluid" src="images/author-img.jpg" alt="author image" />
                    </div>
                    <h4 className="author-name">Altech-author</h4>
                    <p className="author-widget_text">Our deep focus in automation combined with specialized competencies across test automation, RPA development, Devops and Cloud automation make us the preferred automation partner for customers worldwide.</p>
                    </div>
                </div>
                <div className="widget widget-download">
                    <h3 className="widget-title">Case Studies</h3>
                    <ul className="download">
                    <li>
                        <i className="far fa-file-pdf" />
                        <a title="Download">Test Automation</a>
                    </li>
                    <li>
                        <i className="far fa-file-pdf" />
                        <a title="Download">RPA (UiPath)</a>
                    </li>
                    <li>
                        <i className="far fa-file-pdf" />
                        <a title="Download">Performance Engineering</a>
                    </li>
                    </ul>
                </div>
                <div className="widget widget_media_image">
                    <div className="banner-img-box ttm-textcolor-white text-left">
                    <div className="featured-content featured-content-banner">
                        <i className="flaticon flaticon-call" />
                        <div className="featured-title ttm-box-title">
                        <h5>How Can We Help?</h5>
                        </div>
                        <div className="featured-desc">
                        <p>If you need any help, please<br /> feel free to contact us.</p>
                        </div>
                        <ul>
                        <li><i className="far fa-envelope" /><a href="mailto:info@qeagle.com">info@qeagle.com</a></li>
                        </ul>
                    </div>
                    </div>
                </div>                        
            </div>
        );
    }
}

export default Sidebar;